import { Component } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Localization } from "../models/Localizations";
import { Nav, NavLink, Button } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";

export class Home extends Component {
  render() {
    return (
      <div className="container">
        <div className="d-grid gap-2 mx-auto">
          <img className="mx-auto d-block" src="logo_main_via.png"></img>
          <a
            href="https://www.viaticumrescue.eu"
            target="_blank"
            className="btn btn-primary"
            type="button"
          >
            {Localization.Strings.view_website}
          </a>
          <Link to="/rescue-manuals" className="btn btn-primary" type="button">
            {Localization.Strings.manuals_for_rescuers}
          </Link>
          <Link to="/public-manuals" className="btn btn-primary" type="button">
            {Localization.Strings.manuals_for_general_public}
          </Link>
          <a
            href="https://viaticumrescue.eu/mobile_app/app_vids.php"
            target="_blank"
            className="btn btn-primary"
            type="button"
          >
            {Localization.Strings.videos}
          </a>
          <Link to="/about" className="btn btn-primary" type="button">
            {Localization.Strings.change_language}
          </Link>
          <img className="mx-auto d-block" src="erasmus_co_funded.png"></img>
          <small>Project Code: 2019-1-IT02-KA204-062192</small>
          <small>The European Commission support for the production of this website does not constitute an endorsement of the contents which reflects the views only of the authors, and the Commission cannot be held responsible for any use which may be made of the information contained therein.</small>
          <small>© 2022 V.I.A.T.I.C.U.M. All Rights Reserved. Designed by DigiBlocks</small>
        </div>
      </div>
    );
  }
}

// <nav>
// <ul>
//   <li>
//     <a href="https://www.viaticumrescue.eu" target="_blank">
//       {Localization.Strings.view_website}
//     </a>
//   </li>
//   <li>
//     <Link to="/rescue-manuals">
//       {Localization.Strings.manuals_for_rescuers}
//     </Link>
//   </li>
//   <li>
//     <Link to="/public-manuals">
//       {Localization.Strings.manuals_for_general_public}
//     </Link>
//   </li>
//   <li>
//     <a
//       href="https://viaticumrescue.eu/mobile_app/app_vids.php"
//       target="_blank"
//     >
//       {Localization.Strings.videos}
//     </a>
//   </li>
//   <li>
//     <Link to="/about">{Localization.Strings.about}</Link>
//   </li>
// </ul>
// </nav>
