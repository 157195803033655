import LocalizedStrings from 'react-localization';

export class Localization {
    static Strings: any = new LocalizedStrings({
        en: {
            view_full_site: "View Full Site",
            loading: "Loading",
            current_language: "Current Language: ",
            change_language: "Change Language",
            manuals_for_rescuers: "Manuals For Rescuers",
            manuals_for_general_public: "Manuals For General Public",
            view_website: "View Website",
            videos: "Videos",
            about: "About",
            back:"Back",
            reset_app:"Reset App"
        },
        mt: {
            view_full_site: "Żur is-Sit",
            loading: "Loading",
            current_language: "Lingwa: ",
            change_language: "Lingwa",
            manuals_for_rescuers: "Manwali tas-Salvataġġ",
            manuals_for_general_public: "Manwali għall-Pubbliku",
            view_website: "Żur is-Sit",
            videos: "Arkivju",
            about: "About",
            back:"Lura",
            reset_app:"Reset"
        },
        el: {
            view_full_site: "Προβολή πλήρους ιστότοπου",
            loading: "Φόρτωση…",
            current_language: "Τρέχουσα γλώσσα: ",
            change_language: "Αλλαξε γλώσσα",
            manuals_for_rescuers: "Εγχειρίδια για διασώστες",
            manuals_for_general_public: "Εγχειρίδια για το ευρύ κοινό",
            view_website: "Προβολή ιστότοπου",
            videos: "Βίντεο",
            about: "Σχετικά με",
            back:"πίσω",
            reset_app:"Reset App"
        },
        es: {
            view_full_site: "Ver Sitio Completo",
            loading: "Cargando…",
            current_language: "Idioma Actual: ",
            change_language: "Cambiar Idioma",
            manuals_for_rescuers: "Manuales Para Rescatistas",
            manuals_for_general_public: "Manuales Para Público en General",
            view_website: "Ver Sitio Web",
            videos: "Videos",
            about: "Acerca De",
            back:"Espalda",
            reset_app:"Reset App"
        },
        fr: {
            view_full_site: "Voir Le Site Complet",
            loading: "Chargement…",
            current_language: "Langue Courante: ",
            change_language: "Changer De Langue",
            manuals_for_rescuers: "Manuels Pour Les Sauveteurs",
            manuals_for_general_public: "Manuels Pour Le Grand Public",
            view_website: "Voir Le Site Web",
            videos: "Vidéos",
            about: "À Propos",
            back:"Arrière",
            reset_app:"Reset App"
        },
        hu: {
            view_full_site: "Teljes Oldal Megtekintése",
            loading: "Betöltés…",
            current_language: "Aktuális Nyelv: ",
            change_language: "Válts Nyelvet",
            manuals_for_rescuers: "Kézikönyvek beavatkozóknak",
            manuals_for_general_public: "Tájékoztató kézikönyvek a lakosság részére",
            view_website: "Weboldal megtekintése",
            videos: "Videók",
            about: "Rólunk",
            back:"Vissza",
            reset_app:"Reset App"
        },
        it: {
            view_full_site: "View Full Site",
            loading: "Caricamento in corso…",
            current_language: "Lingua Corrente: ",
            change_language: "Cambia Lingua",
            manuals_for_rescuers: "Manuali Per Soccorritori",
            manuals_for_general_public: "Manuali per il pubblico in generale",
            view_website: "Sito Web",
            videos: "Videos",
            about: "Informazioni Sull App",
            back:"Indietro",
            reset_app:"Reset App"
        },
        pt: {
            view_full_site: "Ver Site Completo",
            loading: "Carregando…",
            current_language: "Idioma Atual: ",
            change_language: "Mudar Idioma",
            manuals_for_rescuers: "Manuais de Salvamento",
            manuals_for_general_public: "Manuais Para o Público Em Geral",
            view_website: "Ver Site",
            videos: "Vídeos",
            about: "Cerca de",
            back:"De Volta",
            reset_app:"Reset App"
        },
        sr: {
            view_full_site: "Погледајте целу страницу",
            loading: "Лоадинг…",
            current_language: "Тренутни језик: ",
            change_language: "Промени језик",
            manuals_for_rescuers: "Приручници за спасиоце",
            manuals_for_general_public: "Приручници за ширу јавност",
            view_website: "погледајте веб локацију",
            videos: "Видеос",
            about: "О томе",
            back:"назад",
            reset_app:"Reset App"
        }
    });
}