import { RescueManual, RescueManuals, SupportedLanguage, SupportedLanguages } from "../models/RescueManuals";
import { set, get, keys, clear } from 'idb-keyval';
import { Localization } from "../models/Localizations";
import { trackPromise } from "react-promise-tracker";

export class AppService {
    private readonly cache_version_key: string = "cache-version";
    private readonly manuals_key: string = "rescue-manuals";
    private readonly manuals_api: string = "https://www.viaticumrescue.eu/mobile_app/public_pocket_manuals.php";

    public async getManuals(currentLocale?: SupportedLanguage): Promise<RescueManuals> {
        return await trackPromise(this.getManualsPrivate(currentLocale));
    }

    private async getManualsPrivate(currentLocale?: SupportedLanguage): Promise<RescueManuals> {
        var currentKeys = await keys();
        var containsManualKey = currentKeys.includes(this.manuals_key);

        //Always check for an update
        var manuals: RescueManuals | undefined = undefined;
        if (containsManualKey) {
            manuals = await get(this.manuals_key);
        }

        //Always try to update
        try {
            if (navigator.onLine) {
                manuals = await this.retreiveManuals();
            } else {
                console.log("No Internet connection");
            }
        } catch {
            console.log("No Internet connection");
        }

        //At this point Manuals should always be set
        if (manuals !== undefined) {
            //Check if local has been over-written
            if (currentLocale !== undefined) {
                manuals.Locale = currentLocale;
            } else if (containsManualKey) {
                //Check if language was already set
                var currentManuals: RescueManuals | undefined = await get(this.manuals_key);
                manuals.Locale = currentManuals!.Locale;
            }

            //Fallback
            if (manuals.Locale === undefined) {
                manuals.Locale = this.getLocale();
            }
            
            if (!currentKeys.includes(this.cache_version_key) || (await get(this.cache_version_key)) !== manuals.CacheVersion) {
                await clear();
                await set(this.cache_version_key, manuals.CacheVersion);
            }

            //Update Locale: This should be moved
            Localization.Strings.setLanguage(manuals.Locale.isoCode);
            await set(this.manuals_key, manuals);

            for (const currentManual of manuals.RescueManuals) {
                var currentManualKey = this.getManualKey(currentManual);
                currentKeys = await keys();
                var containsKey = currentKeys.includes(currentManualKey);
                if (!containsKey) {
                    await set(currentManualKey, await this.getManual(currentManual.location));
                }
            }

            const publicManualsMap = new Map(Object.entries(manuals.PublicManuals));
            for (const currentManual of publicManualsMap.get(manuals.Locale.isoCode)) {
                currentManualKey = this.getManualKey(currentManual, manuals!.Locale.isoCode);
                currentKeys = await keys();
                containsKey = currentKeys.includes(currentManualKey);
                if (!containsKey) {
                    await set(currentManualKey, await this.getManual(currentManual.location));
                }
            }

            return manuals;
        } else {
            throw console.error("Unable to retreive manual data");
        }
    }

    public async getManualBlob(currentManualKey: string): Promise<Blob | undefined> {
        return await get(currentManualKey);
    }

    private async retreiveManuals(): Promise<RescueManuals> {
        const response = await fetch(this.manuals_api, {
            method: 'GET'
        });
        return await response.json();
    }

    private async getManual(currentManual: string): Promise<Blob> {
        const response = await fetch(currentManual, {
            method: 'GET'
        });
        return await response.blob();
    }

    public getManualKey(rescueManual: RescueManual, languageISO?: string) {
        if (languageISO !== undefined) {
            return languageISO + rescueManual.manual_code + rescueManual.version;
        }
        return rescueManual.manual_code + rescueManual.version;
    }

    public async updateLocale(newLocale: string): Promise<RescueManuals> {
        var currentLocale = this.findLocale(newLocale);
        return await this.getManuals(currentLocale);
    }

    public async resetApp(): Promise<RescueManuals> {
        await clear();
        return await this.getManuals();
    }

    private getLocale(): SupportedLanguage {
        try {
            var language = navigator.language;
            var parts = language.split('-');
            var languageISO = parts[0];
            return this.findLocale(languageISO);
        } catch (error) {
            //If not possible fallback to English
            console.log("Language not found. Default to en.");
            return SupportedLanguages.SupportedLanguages[0];
        }
    }

    private findLocale(currentLocale: string): SupportedLanguage {
        var filteredLanguage = SupportedLanguages.SupportedLanguages.filter(l => l.isoCode === currentLocale);
        if (filteredLanguage.length === 1) {
            return filteredLanguage[0];
        } else {
            console.log("Language not found. Default to en.");
            return SupportedLanguages.SupportedLanguages[0];
        }
    }
}