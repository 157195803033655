import React, { useEffect, useState } from "react";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import LoadingOverlay from "react-loading-overlay-ts";
import { AppService } from "./services/app.service";
import { Routes, Route, Outlet } from "react-router-dom";
import { RescueManuals } from "./models/RescueManuals";
import { NoMatch } from "./components/SharedComponents";
import { About } from "./components/About";
import { Home } from "./components/Home";
import {
  PublicManualsPage,
  RescueManualsPage,
  ShowManual,
} from "./components/Manuals";
import "./App.css";
import { Localization } from "./models/Localizations";

function App() {
  const { promiseInProgress } = usePromiseTracker();
  const appService = new AppService();
  const [data, setData] = useState<RescueManuals>();
  useEffect(() => {
    const fetchData = async () => {
      const result = await appService.getManuals();
      setData(result);
    };
    fetchData();
    //empty array mean that we only want to fetch //data one time when the component mount
  }, []);

  return (
    <div className="App">
      <LoadingOverlay
        active={promiseInProgress}
        spinner
        text={Localization.Strings.loading}
      >
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="rescue-manuals" element={<RescueManualsPage />} />
            <Route path="public-manuals" element={<PublicManualsPage />} />
            <Route path="show-manual/:manualid" element={<ShowManual />} />
            <Route path="about" element={<About />} />
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </LoadingOverlay>
    </div>
  );
}

function Layout() {
  return (
    <div>
      <Outlet />
    </div>
  );
}

export default App;
