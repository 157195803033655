export interface RescueManuals {
    CacheVersion: Number;
    Locale: SupportedLanguage;
    RescueManuals: RescueManual[];
    PublicManuals: Map<string, RescueManual[]>;
}

export interface RescueManual {
    name: string;
    manual_code: string;
    location: string;
    version: string;
}

export class SupportedLanguages {
    static SupportedLanguages: SupportedLanguage[] = [
        { isoCode: "en", name: "GBR", dropDownCode: "GB" },
        { isoCode: "it", name: "ITA", dropDownCode: "IT" },
        { isoCode: "fr", name: "FRA", dropDownCode: "FR" },
        { isoCode: "el", name: "GRC", dropDownCode: "GR" },
        { isoCode: "hu", name: "HUN", dropDownCode: "HU" },
        { isoCode: "mt", name: "MLT", dropDownCode: "MT" },
        { isoCode: "sr", name: "SRB", dropDownCode: "RS" },
        { isoCode: "es", name: "ESP", dropDownCode: "ES" },
        { isoCode: "pt", name: "PRT", dropDownCode: "PT" }
    ];
}

export interface SupportedLanguage {
    isoCode: string;
    name: string;
    dropDownCode: string;
}