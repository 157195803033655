import { Component } from "react";
import { AppService } from "../services/app.service";
import { BackButton } from "./SharedComponents";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { Link } from "react-router-dom";
import { RescueManual } from "../models/RescueManuals";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/full-screen/lib/styles/index.css";
import "bootstrap/dist/css/bootstrap.min.css";

export class ShowManual extends Component<any, any> {
  constructor(props: any) {
    var locations = window.location.pathname.split("/");
    var manualId = locations[locations.length - 1];
    super(props);
    this.state = {
      manual: null,
      manualId: manualId,
    };
  }

  async componentDidMount() {
    const appService = new AppService();
    const currentManualPDF = await appService.getManualBlob(
      this.state.manualId
    );
    if (currentManualPDF !== undefined) {
      var blob = currentManualPDF!;
      var url = URL.createObjectURL(blob);
      var manual = (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.12.313/build/pdf.worker.min.js">
          <Viewer fileUrl={url} />
        </Worker>
      );
      this.setState({ manual });
    }
  }

  render() {
    const { manual } = this.state;
    return (
      <div>
        <BackButton />
        {manual}
      </div>
    );
  }
}

export class RescueManualsPage extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      buttons: null,
    };
  }

  async componentDidMount() {
    const result = await new AppService().getManuals();
    const buttons = result.RescueManuals.map((r) => {
      return createPDFButton(r);
    });

    this.setState({ buttons });
  }

  render() {
    const { buttons } = this.state;
    return (
      <div>
        <BackButton />
        <div className="container">
          <div className="d-grid gap-2 mx-auto">{buttons}</div>
        </div>
      </div>
    );
  }
}

export class PublicManualsPage extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      buttons: null,
    };
  }

  async componentDidMount() {
    const result = await new AppService().getManuals();
    const publicManualsMap = new Map(Object.entries(result.PublicManuals));
    const buttons = publicManualsMap
      .get(result.Locale.isoCode)
      .map((r: RescueManual) => {
        return createPDFButton(r, result.Locale.isoCode);
      });

    this.setState({ buttons });
  }

  render() {
    const { buttons } = this.state;
    return (
      <div>
        <BackButton />
        <div className="container">
          <div className="d-grid gap-2 mx-auto">{buttons}</div>
        </div>
      </div>
    );
  }
}

function createPDFButton(rescueManual: RescueManual, languageISO?: string) {
  var link =
    "/show-manual/" + new AppService().getManualKey(rescueManual, languageISO);
  return (
    <Link to={link} className="btn btn-primary" type="button">
      {rescueManual.name}
    </Link>
  );
}
