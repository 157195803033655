import { Component } from "react";
import { Link } from "react-router-dom";
import { Localization } from "../models/Localizations";

export class BackButton extends Component {
  render() {
    return (
      <div className="p-4">
        <Link
          to="/"
          className="position-absolute top-0 start-0 btn btn-primary pull-right"
          type="button"
        >
          {Localization.Strings.back}
        </Link>
      </div>
    );
  }
}

export class NoMatch extends Component {
  render() {
    return (
      <div>
        <h2>Something went wrong!</h2>
        <p>{BackButton}</p>
      </div>
    );
  }
}
