import { Component } from "react";
import { Link } from "react-router-dom";
import { Localization } from "../models/Localizations";
import { SupportedLanguages } from "../models/RescueManuals";
import { AppService } from "../services/app.service";
import { BackButton } from "./SharedComponents";
import ReactFlagsSelect from "react-flags-select";

export class About extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      manuals: null,
    };
  }

  async componentDidMount() {
    const manuals = await new AppService().getManuals();
    this.setState({ manuals });
  }

  handleChange = async (e: string) => {
    const newLanguageISO = SupportedLanguages.SupportedLanguages.filter(x=>x.dropDownCode===e)[0].isoCode;
    console.log(newLanguageISO);
    const manuals = await new AppService().updateLocale(newLanguageISO);
    this.setState({ manuals });
  };

  resetApp = async (e: any) => {
    const manuals = await new AppService().resetApp();
    this.setState({ manuals });
  };

  render() {
    const { manuals } = this.state;

    return (
      <div>
        <BackButton />
        <h3>
          {Localization.Strings.current_language} {manuals?.Locale.name}
        </h3>
        <br></br>
        <div className="flagsDropDown">
          <ReactFlagsSelect
            selected={manuals?.Locale.name}
            customLabels={{
              GB: "GBR",
              IT: "ITA",
              FR: "FRA",
              GR: "GRC",
              HU: "HUN",
              MT: "MLT",
              RS: "SRB",
              ES: "ESP",
              PT: "PRT",
            }}
            placeholder={Localization.Strings.change_language}
            showOptionLabel={true}
            countries={SupportedLanguages.SupportedLanguages.map(x=>x.dropDownCode)}
            onSelect={this.handleChange}
          />
        </div>
        <div className="p-5">
          <Link
            onClick={this.resetApp}
            to={""}
            className="btn btn-primary"
            type="button"
          >
            {Localization.Strings.reset_app}
          </Link>
        </div>
      </div>
    );
  }
}
